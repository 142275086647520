import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="#E67D00"
        d="M868.48 401.28c-.64 21.12-1.28 40.32-8.96 58.88-4.48 10.88-13.44 14.08-23.68 14.72-10.24.64-17.28-4.48-21.76-12.8a39.424 39.424 0 0 1-3.84-26.88c8.96-42.88 0-81.28-23.68-117.12-16-24.96-40.32-39.04-68.48-46.08-19.2-4.48-38.4-5.76-58.24-4.48-8.96 1.28-17.92 0-26.24-3.2a20.224 20.224 0 0 1-12.16-14.72c-1.92-12.8 1.92-26.88 16-33.28 14.08-6.4 28.16-7.68 42.88-6.4 62.08 5.12 113.28 30.08 153.6 77.44 20.48 24.32 32 55.04 32.64 86.4.64 10.24 1.28 19.84 1.92 27.52zm0 0"
      />
      <path
        fill="#E67D00"
        d="M773.76 404.48c0 7.68-3.2 20.48-12.8 31.36-6.4 7.68-15.36 9.6-24.96 8.32-7.04-1.28-10.24-7.04-12.16-14.08a33.792 33.792 0 0 1 0-16c1.28-10.24.64-20.48-2.56-30.08-6.4-18.56-21.12-26.24-39.68-28.16-5.76-.64-11.52-.64-17.28-1.28-11.52-1.28-18.56-9.6-19.2-21.76-.64-10.24 6.4-18.56 17.28-21.12 26.88-6.4 52.48-4.48 74.88 12.8 23.04 18.56 36.48 42.88 36.48 80zm0 0"
      />
      <path
        fill="#0B0C0E"
        d="M422.4 712.32c-35.2-.64-64-10.88-87.04-35.2-28.8-31.36-29.44-76.8-3.2-112.64 23.04-30.72 54.4-46.72 92.8-49.92 32-3.2 62.08 4.48 87.04 25.6 37.76 32.64 42.24 84.48 10.88 124.16-26.88 32.64-62.08 46.72-100.48 48zm-33.92-47.36c12.8 0 24.96-5.12 33.92-14.08 19.2-19.2 12.8-48-12.8-56.32a44.48 44.48 0 0 0-22.4-1.28c-9.6 1.92-18.56 7.04-25.6 14.08-9.6 10.88-13.44 23.04-7.68 37.12 5.12 12.8 19.2 20.48 34.56 20.48zm62.08-58.24c10.24 0 19.2-7.68 19.2-16.64 0-7.68-6.4-14.08-15.36-14.08-10.24 0-19.2 7.68-19.2 16.64 0 7.68 6.4 14.08 15.36 14.08zm0 0"
      />
      <path
        fill="#FEFEFE"
        d="M388.48 664.96c-15.36.64-29.44-7.68-34.56-20.48-5.76-14.08-1.92-26.24 7.68-37.12a45.44 45.44 0 0 1 25.6-13.44c7.04-1.92 15.36-1.92 22.4.64 25.6 8.32 32 36.48 12.8 56.32a48 48 0 0 1-33.92 14.08zm62.08-58.24c-8.32 0-15.36-6.4-15.36-14.08 0-8.96 8.96-16.64 19.2-16.64 8.32 0 15.36 6.4 15.36 14.08 0 8.96-8.96 16.64-19.2 16.64zm0 0"
      />
      <path
        fill="#D5261E"
        d="M444.8 791.04c-57.6-.64-117.12-9.6-172.8-33.92-32.64-14.08-62.72-33.28-86.4-61.44-39.04-47.36-46.72-100.48-25.6-157.44 15.36-41.6 40.96-76.16 69.76-109.44 38.4-44.16 81.28-81.92 131.84-112 28.16-16.64 57.6-26.88 90.88-26.24 23.04.64 42.24 9.6 51.84 32 7.68 17.92 7.68 35.84 0 53.76-1.92 4.48-3.84 9.6-4.48 14.72-.64 6.4 2.56 9.6 8.96 8.96 3.2 0 5.76-1.92 8.32-3.2 37.12-18.56 79.36-26.24 120.96-22.4 37.76 3.2 61.44 41.6 50.56 77.44-2.56 7.04-6.4 13.44-12.16 19.2-6.4 7.04-4.48 16 4.48 17.92 10.88 3.2 22.4 3.84 32.64 7.68 17.92 6.4 34.56 14.72 46.72 30.72 15.36 19.84 18.56 42.88 14.08 66.56-8.96 48.64-34.56 87.68-72.32 119.04-39.04 32.64-85.12 52.48-134.4 64.64-40.32 8.96-81.28 14.08-122.88 13.44zm-24.32-56.32c49.92-.64 96-10.24 140.16-31.36 34.56-16.64 64.64-39.04 85.12-72.96 24.96-40.96 19.84-87.04-12.8-121.6-26.24-26.88-58.88-40.96-94.72-49.28-33.92-7.68-68.48-9.6-103.04-5.12-47.36 5.76-90.88 19.2-131.2 45.44-24.32 16-45.44 35.84-58.88 62.72-17.28 32.64-17.28 65.28 1.92 96.64 12.8 21.76 32.64 36.48 54.4 48 37.76 19.84 78.72 26.88 119.04 27.52zm0 0"
      />
      <path
        fill="#FEFEFE"
        d="M420.48 734.72c-40.96-.64-81.28-7.04-119.04-26.88-21.76-11.52-41.6-26.24-54.4-48-19.2-32-19.2-64-1.92-96.64 13.44-26.24 34.56-46.08 59.52-62.72 39.68-26.24 83.84-40.32 130.56-46.08 34.56-4.48 69.12-2.56 103.04 5.12 35.84 8.32 68.48 22.4 94.72 49.28 33.28 33.92 37.76 81.28 12.8 121.6-20.48 33.28-50.56 56.32-85.12 72.96-43.52 21.12-90.24 30.72-140.16 31.36zm1.92-22.4c39.04-1.28 73.6-15.36 99.84-48 31.36-39.68 26.88-91.52-10.88-124.16-24.96-21.76-54.4-28.8-87.04-26.24-37.76 3.2-69.76 19.2-92.8 49.92-26.24 35.84-25.6 81.28 3.2 112.64 23.04 24.96 52.48 35.2 87.68 35.84zm0 0"
      />
    </svg>
  )
}

export default memo(SVG)
