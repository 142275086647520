import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path
        fill="#170B1A"
        d="M199.111 0H824.89C934.684 0 1024 89.316 1024 199.111V824.89c0 109.794-89.316 199.11-199.111 199.11H199.11C89.316 1024 0 934.684 0 824.889V199.11C0 89.316 89.316 0 199.111 0z"
      />
      <path
        fill="#25F4EE"
        d="M511.431 302.08c.569-64.284 0-128.569.569-192.853h131.413c-.569 11.377 1.138 22.755 2.845 33.564h-96.711v522.24c.569 22.187-5.12 44.373-15.93 63.716-17.066 29.582-48.924 50.062-83.057 52.906-21.618 1.707-43.804-2.275-63.147-13.084-14.79-7.965-27.306-19.342-36.977-32.996 33.564 18.774 77.368 17.067 109.795-3.982C491.52 712.25 512 675.84 512 638.293c-.569-112.07-.569-224.142-.569-336.213zm216.747-36.978c18.204 11.378 38.684 20.48 59.733 25.031 12.516 2.845 25.031 3.983 38.116 3.983v29.582c-37.547-8.534-72.25-29.582-97.85-58.596z"
      />
      <path
        fill="#25F4EE"
        d="M274.773 428.942c47.218-29.582 104.676-41.529 159.29-33.564v31.289c-14.792.569-29.014 2.275-43.805 5.12-35.271 7.395-68.836 22.186-97.85 43.804-31.288 23.325-55.181 55.182-71.68 90.453-15.928 33.565-23.892 70.543-23.324 108.09 0 40.96 11.378 80.782 30.72 116.622 9.103 16.497 19.343 32.426 32.996 45.51-27.876-19.342-51.2-45.51-68.267-75.093-23.324-39.253-34.702-85.333-33.564-131.413 1.707-42.098 13.653-83.627 35.84-120.036 19.342-32.426 47.218-60.87 79.644-80.782z"
      />
      <path
        fill="#FFF"
        d="M549.547 142.791h97.28c3.413 18.773 10.24 36.409 18.773 53.476 13.653 26.169 32.996 49.493 58.027 64.853 1.706 1.138 2.844 2.276 3.982 3.982 25.6 29.014 60.302 50.062 98.418 58.596.569 34.133 0 68.835 0 102.969-64.285.569-128.57-19.911-180.907-57.458 0 81.92 0 163.84.569 245.76 0 10.809.569 21.618 0 32.995-2.845 39.823-15.36 79.076-35.271 113.778-17.067 30.151-40.391 56.89-68.267 77.37-35.84 26.737-80.213 41.528-124.587 42.666-22.755.569-45.51-.57-67.697-5.69-31.29-6.826-60.871-19.91-87.04-38.115l-1.707-1.706c-13.084-13.085-23.893-29.014-32.996-45.511-19.342-35.272-30.72-75.663-30.72-116.623-.568-36.977 7.396-74.524 23.325-108.089 16.498-35.27 40.96-67.128 71.68-90.453 29.013-21.618 62.578-36.409 97.849-43.804 14.222-2.845 29.013-4.551 43.804-5.12.57 13.084 0 26.169.57 38.684v66.56c-16.499-5.689-34.703-5.689-51.77-1.707-20.48 4.552-39.822 13.654-55.75 27.307-9.672 8.533-18.205 18.773-23.894 30.151-10.24 19.342-13.654 42.098-11.378 63.716 2.276 21.049 11.378 41.529 25.031 57.458 9.102 11.377 21.049 19.91 32.996 27.875 9.67 13.653 22.186 25.031 36.977 32.996 19.343 10.24 41.53 14.79 63.147 13.084 34.133-2.275 65.991-23.324 83.058-52.907 10.809-19.342 16.498-41.528 15.929-63.715 1.138-175.218.569-349.298.569-523.378z"
      />
      <path
        fill="#FE2C55"
        d="M646.827 142.791c11.377.569 22.755 0 34.702 0 0 38.116 11.947 76.231 34.133 107.52 2.845 3.982 5.69 7.396 8.534 10.809-25.032-15.36-44.943-38.684-58.027-64.853-8.533-16.498-15.36-34.703-19.342-53.476zm179.2 180.907c12.515 2.844 25.03 3.982 38.115 3.982v132.551c-64.853.569-129.706-21.049-182.613-59.164v262.826c.569 19.911-1.138 39.823-5.689 59.165-12.516 59.164-47.787 112.64-96.711 147.342-26.169 18.773-55.751 31.858-86.471 38.684-37.547 8.534-76.8 7.965-113.778-1.706-43.804-11.378-84.764-35.84-115.484-69.405 26.168 18.774 55.75 31.29 87.04 38.116 22.186 5.12 44.942 6.258 67.697 5.689 44.374-1.138 88.747-15.93 124.587-42.667 27.876-20.48 50.631-47.218 68.267-77.369 19.91-34.702 32.426-73.955 35.27-113.778.57-10.808.57-21.617 0-32.995-.568-81.92-.568-163.84-.568-245.76 52.338 37.547 116.622 58.027 180.907 57.458-.57-34.134 0-68.836-.57-102.97z"
      />
      <path
        fill="#FE2C55"
        d="M434.631 426.098c12.516 0 25.6.569 38.116 2.275v135.965c-18.205-6.258-38.685-6.827-57.458-2.276-35.84 7.965-65.991 35.271-78.507 69.974-12.515 34.133-7.395 73.955 14.222 102.968-12.515-7.395-23.893-16.497-32.995-27.875-13.653-15.929-22.756-36.409-25.031-57.458-2.276-21.618 1.138-44.373 11.378-63.715 5.688-11.378 14.222-21.618 23.893-30.152 15.929-13.653 35.84-22.186 55.751-27.306 17.067-3.982 35.271-3.982 51.769 1.706v-66.56c-1.138-11.377-.569-24.462-1.138-37.546z"
      />
    </svg>
  )
}

export default SVG
